* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
  overflow: hidden;
}

body {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-flow: column;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.react-wallpaper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  opacity: 0.75;
}

.whole-container {
  width: 95%;
  max-width: 95%;
  min-width: 95%;
  margin: auto;
  padding: 1% 0;
  height: 95%;
  max-height: 95%;
  min-height: 95%;
}

.grid-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: row;
  scroll-behavior: smooth;
}

.grid-column {
  overflow: auto;

  scroll-behavior: smooth;
  overflow-y: scroll;
  height: 100vh;
  display: flex;
  flex-flow: column;
  scroll-behavior: smooth;
}

.grid-section {
  margin: auto;
  width: 100%;
  overflow: auto;
}

.nav-image-container {
  overflow: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  width: 100% !important;
  height: 20% !important;
  border-radius: 0;
  margin: 2% 0;
  background-color: black;
  background: linear-gradient(rgba(0, 0, 0, 0), black);
  border: 2px solid rgb(218, 216, 210);
}

.nav-image-container img {
  display: inline-block;
  text-align: center;
  height: 80% !important;
}

.active {
  pointer-events: none;
  background: grey;
  background-position: left;
}

.card-active {
  pointer-events: none;
  color: grey;
  transition: all 0.5s ease;
}

.card-button {
  margin: 1vh auto !important;
  font-size: 3vh !important;
  letter-spacing: 0.5vw !important;
  display: inline-block;
  border: 0.1em solid #ffffff;
  background-color: #ffffff;
  text-align: center;
}

.inactive {
  display: none !important;
}

#videoPlayer {
  min-width: 95%;
  max-width: 95%;
  min-height: 100%;
  max-height: 100%;
  display: block;

  margin: 0 auto 0 auto;
  border-radius: 4px 4px 0 0;
}

.video-container {
  position: relative;
  width: 100%;
  background-attachment: scroll;
}

.video-overlay {
  height: 100%;
  width: 95%;
  position: absolute;
  margin-left: -0.52vw;
  top: 0px;
  left: 2vw;
  z-index: 2;
  background: linear-gradient(rgba(0, 0, 0, 0), black);
  opacity: 0.3;
}

.overlay-quote {
  position: absolute;
  bottom: 0px;
  color: white;
  font-size: 4vh;
  text-align: left;
  margin-left: 3vw;
}

.overlay-author {
  position: absolute;
  bottom: 0px;
  right: 2vw;
  color: white;
  font-size: 2vh;
}

.nav-arrow-up {
  width: 80% !important;

  background-color: hsl(0, 0%, 44%, 50%);
  min-height: 4vh;
  max-height: 4vh;
  margin: auto;
  border-radius: 100% 100% 0 0;
}

.nav-arrow-down {
  width: 80% !important;

  background-color: hsl(0, 0%, 44%, 50%);
  min-height: 4vh;
  max-height: 4vh;
  margin: auto;
  border-radius: 0 0 100% 100%;
}

.card-styling {
  width: 95%;
  min-width: 95%;
  max-width: 95%;
  display: block;
  margin: auto;
  color: white;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow-y: scroll;
}

.flex-container {
  height: 95vh;
  display: flex;
  flex-direction: column;
}

.card-container {
  transition: all 1s;
  flex-grow: 1;
  height: 50%;
  overflow-y: scroll;
}

/* Content With the Text for each Media/Anime */
.card-content-container {
  height: 75%;
  min-height: 75%;
  max-height: 75%;
  text-align: left;
  overflow-y: scroll;
  padding: 0 4%;
  border-radius: 1%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.card-text {
  overflow-y: scroll;
}

.media-text {
  font-size: 2vh;
  transition: all 0.5s ease;
  margin-top: 0;
  padding-bottom: 0;
  color: black;
  opacity: 1;
  white-space: pre-wrap;
}

.media-text-gen {
  font-style: italic;
  font-size: 2vh;
  transition: all 0.5s ease;
  margin-top: 0;
  padding-bottom: 0;
  color: white;
  opacity: 1;
  white-space: pre-wrap;
  font-size: 2vh;
  transition: all 0.5s ease;
  margin-top: 0;
  padding-bottom: 0;
  color: white;
  opacity: 1;
  text-shadow: 4px 4px 4px black;
}

.card-container-tab {
  flex-grow: 1;
  margin-top: auto !important;
  overflow-y: scroll;
}

#hnk {
  transition: all 1s ease;
  background: url(https://littleanimeblog.files.wordpress.com/2019/02/8d84c-houseki-no-kuni-land-of-the-lustrous-phos.png)
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

#pmmm {
  transition: all 1s ease;
  background: url(https://vistapointe.net/images/puella-magi-madoka-magica-3.jpg)
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

#csm {
  transition: all 1s ease;
  background: url(https://wallpapercave.com/wp/wp8650131.png) no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

#wha {
  transition: all 1s ease;
  background: url(https://preview.redd.it/kkhl0ff5u8c51.png?width=1920&format=png&auto=webp&s=18e78631b27e8370449d201f6951928679794e54)
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

#tpnl {
  transition: all 1s ease;
  background: url(https://www.kolpaper.com/wp-content/uploads/2021/03/Promised-Neverland-Wallpapers-7.jpg)
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

/* CSS Animate */

/* .animate__animated.animate__fadeInDown {
  --animate-delay: 2s;
} */

/* CSS Transitions */

.slide-in {
  position: absolute;
  left: -100px;
  width: 100px;
  height: 100px;
  background: blue;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  animation-delay: 2s;
}
@keyframes slide {
  100% {
    left: 0;
  }
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.move-in {
  animation: moveIn ease 1s;
}
@keyframes moveIn {
  from {
    top: 0;
    left: 0;
  }
  to {
    top: 100px;
    left: 100px;
  }
}

/* 
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

/* @media screen and (max-width: 1366px), screen and (max-height: 768px) {
  .card-styling {
    min-height: 39vh;
    max-height: 39vh;
  }

  .card-content-container {
    min-height: 30vh;
    max-height: 30vh;
  }
}

@media screen and (max-width: 1536px), screen and (max-height: 864px) {
  .card-styling {
    min-height: 40vh;
    max-height: 40vh;
  }

  .card-content-container {
    min-height: 31vh;
    max-height: 31vh;
  }
}

@media screen and (max-width: 1920px), screen and (max-height: 1080px) {
  .card-styling {
    min-height: 39vh;
    max-height: 39vh;
  }

  .card-content-container {
    min-height: 30vh;
    max-height: 30vh;
  }
}

@media screen and (max-width: 2560px), screen and (max-height: 1440px) {
  .card-styling {
    min-height: 38vh;
    max-height: 38vh;
  }

  .card-content-container {
    min-height: 30vh;
    max-height: 30vh;
  }
} */

@media screen and (min-width: 1026px), screen and (min-height: 1368px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
    font-family: "Roboto", sans-serif;
  }

  body {
    width: 100%;
    height: 100vh;
    /* border: 5px dashed red; */
    display: flex;
    flex-flow: column;
    scroll-behavior: smooth;
  }

  .profile-pat {
    transition: 1s ease all;
    margin-top: 2%;
    margin-right: 2%;
    position: fixed;
    top: 0;
    z-index: 3;
    right: 0;
    width: 40%;
    height: 10%;
    display: flex;
    justify-content: flex-end;
  }

  .pfp-pat {
    position: relative;
  }

  .title-div {
  }

  .title {
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    font-weight: 700;
    position: fixed;
    top: 2%;
    font-size: 6vh;
    color: white;
    z-index: 3;
    margin-left: 4%;
    letter-spacing: 4vh;
    transition: 1s ease all;
    opacity: 0.5;
  }

  .title2 {
    writing-mode: vertical-lr;
    font-weight: 700;
    position: fixed;
    top: 8%;
    font-size: 6vh;
    color: white;
    z-index: 3;
    margin-left: 10%;
    letter-spacing: 4vh;
    transition: 1s ease all;
    opacity: 0.5;
  }

  .react-wallpaper {
    position: fixed;

    opacity: 0.75;
  }

  .nav-image-container {
    margin-top: 0;
  }

  #videoPlayer {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    margin: 0;
    border-radius: 0;
  }

  .video-container {
    position: relative;
    width: 100%;
    background-attachment: scroll;
  }

  .video-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: 2;
    background: linear-gradient(rgba(0, 0, 0, 0), black);
    opacity: 0.3;
    margin-left: 0;
    left: 0;
  }

  .card-container-pat {
    cursor: pointer;
    transition: all ease 1s;
    /* border: 4px dashed yellow; */
    padding: 2% 4%;
    overflow: auto;
    border-left: 2px solid white;
    background: linear-gradient(rgba(50, 50, 50, 0.75), rgba(0, 0, 0, 0.75));
  }

  .card-container-pat-pre {
    /* border: 4px dashed yellow; */
    width: 1%;
    transition: all ease 1s;
    padding: 2% 4%;
    margin-right: 2%;
    overflow: auto;
    border-left: 2px solid white;
    background: linear-gradient(rgba(50, 50, 50, 0.75), rgba(0, 0, 0, 0.75));
  }

  .card-container-pat-pre:hover {
    cursor: pointer;
    transition: all ease 1s;
    width: 5%;
    /* border: 4px dashed yellow; */
    padding: 2% 4%;
    overflow: auto;

    background: linear-gradient(rgba(50, 50, 50, 0.75), rgba(0, 0, 0, 0.75));
  }

  .card-container {
    margin-bottom: 2%;
    margin-right: 2%;
    width: 40%;
    /* border: 4px dashed yellow; */
    bottom: 0;
    right: 0;
    position: fixed;
    overflow: auto;
    z-index: 3;
  }

  .card-button {
    font-size: 2vh !important;
  }

  .overlay-quote {
    width: 40%;
    /* border: 4px dashed yellow; */
    font-size: 3vh;
    position: fixed;
    bottom: 0;
    margin-left: 2%;
  }

  .overlay-author {
    position: fixed;
    /* border: 4px dashed yellow; */
    bottom: 0;
    width: 40%;
    left: 0;
    font-size: 3vh;
    color: white;
    text-align: right;
  }

  .MuiPaper-root {
    background-color: rgba(0, 0, 0, 0) !important;
  }

  /* Card Container */

  .card-styling {
    color: white;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    overflow-y: scroll;
    /* border: 8px dashed teal; */
  }

  .flex-container {
    /* border: 15px dashed black; */
    height: 95vh;
    display: flex;
    flex-direction: column;
  }

  .card-container {
    border: 2px solid white;
    transition: all 1s;
    flex-grow: 1;
    height: 40%;
    background: linear-gradient(rgba(50, 50, 50, 0.75), rgba(0, 0, 0, 0.75));
    overflow-y: scroll;
  }

  /* Content With the Text for each Media/Anime */
  .card-content-container {
    border-top: 2px solid #dad8d2;
    border-bottom: 2px solid #dad8d2;
    height: 80%;
    min-height: 80%;
    max-height: 80%;
    text-align: left;
    overflow-y: scroll;
    padding: 2% 4%;
    border-radius: 1%;
    /* Changed Position for fixed height */
    position: absolute;
    z-index: 2;
    /* Center Align */
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  .card-text {
    overflow-y: scroll;
  }

  .media-text {
    font-size: 2vh;
    transition: all 0.5s ease;
    margin-top: 0;
    padding-bottom: 0;
    color: white;
    opacity: 1;
    text-shadow: 4px 4px 4px black;
  }

  /* Card Container */

  /* Media Tabs */

  .card-container-tab {
    /* border: 2px dashed yellow; */
    border: 1px solid white;
    bottom: 20%;
    width: 40%;
    position: fixed;
    flex-grow: 1;
    margin-top: auto !important;
    overflow-y: scroll;
    z-index: 2;
    height: 20%;
    margin-bottom: 2%;
    margin-left: 2%;
    scroll-behavior: smooth;
  }

  .grid-column {
    scroll-behavior: smooth;
    overflow-y: scroll;
    white-space: nowrap;
  }

  .media-logo {
    max-width: 100%;
  }

  .nav-container {
    display: inline-block;
  }

  .nav-image-container {
    display: inline-block;
    text-align: center;
    width: 100% !important;
    margin-bottom: 0;
    margin: 0;
    height: 100% !important;
    border-radius: 0;
    background-color: black;
    background: linear-gradient(rgba(0, 0, 0, 0), black);
    border: 2px solid rgb(218, 216, 210);
    transition: all 1s ease;
  }

  .nav-image-container img {
    display: inline-block;
    text-align: center;
    height: 100% !important;
    transition: all 1s ease;
  }

  .nav-image-container:hover {
    /* background: grey; */
    background: rgb(98, 98, 98);
    transition: all 1s ease;
  }

  /* Media Tabs */

  /* Card Tabs */

  .card-button {
    background-color: rgba(0, 0, 0, 0) !important;
    border: none;
    color: white;
    font-weight: 600;
    margin: 0.5em auto !important;
  }

  .active {
    pointer-events: none;
    /* background: grey; */
    background: linear-gradient(rgba(0, 0, 0, 0), rgb(98, 98, 98));
    background-position: left;
    filter: initial;
  }

  .nav-image-container img {
    margin: auto;
    display: block;
    filter: brightness(0) invert(1);
  }

  /* Card Tabs */

  .overlay-quote {
    bottom: 7vh;
  }

  .overlay-author {
    bottom: 3vh;
  }

  .menu-button {
    position: fixed;
  }

  /* De-Decor */

  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  /* De-Decor */

  .react-wallpaper {
    position: fixed;

    opacity: 1;
  }

  /* Wallpaper Mobile */

  #hnk {
    filter: brightness(0%);
    transition: all 1s ease;
    background: url(https://kawaii-mobile.com/wp-content/uploads/2018/02/Houseki-no-Kuni-Phosphophyllite-1080x1920-168x300.jpg)
      no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  #pmmm {
    filter: brightness(0%);
    transition: all 1s ease;
    background: url(https://mfiles.alphacoders.com/298/298775.jpg) no-repeat
      center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  #csm {
    filter: brightness(0%);
    transition: all 1s ease;
    background: url(https://w0.peakpx.com/wallpaper/426/973/HD-wallpaper-monochrome-anime-girls-chainsaw-man-makima-chainsaw-man.jpg)
      no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  #wha {
    filter: brightness(0%);
    transition: all 1s ease;
    background: url(https://i2.wp.com/theturnaroundblog.com/wp-content/uploads/2019/04/9781632367709_IL_1_53404.jpg?fit=547%2C779&ssl=1)
      no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  #tpnl {
    filter: brightness(0%);
    transition: all 1s ease;
    background: url("./wallpapers/mobiletpnl.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }
}

@media screen and (max-width: 1026px) and (max-height: 1368px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
    font-family: "Roboto", sans-serif;
  }

  body {
    width: 100%;
    height: 100vh;
    /* border: 5px dashed red; */
    display: flex;
    flex-flow: column;
    scroll-behavior: smooth;
  }

  .react-wallpaper {
    position: fixed;

    opacity: 0.75;
  }

  .nav-image-container {
    margin-top: 0;
  }

  #videoPlayer {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    margin: 0;
    border-radius: 0;
  }

  .video-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: 2;
    background: linear-gradient(rgba(0, 0, 0, 0), black);
    opacity: 0.3;
    margin-left: 0;
    left: 0;
  }

  .card-container {
    /* border: 4px dashed yellow; */
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  .card-button {
    font-size: 2vh !important;
  }

  .overlay-quote {
    font-size: 2.35vh;
  }

  .MuiPaper-root {
    background-color: rgba(0, 0, 0, 0) !important;
  }

  /* Card Container */

  .card-styling {
    color: white;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    overflow-y: scroll;
    /* border: 8px dashed teal; */
  }

  .flex-container {
    /* border: 15px dashed black; */
    height: 95vh;
    display: flex;
    flex-direction: column;
  }

  .card-container {
    transition: all 1s;
    flex-grow: 1;
    height: 40%;
    background: linear-gradient(black, rgba(0, 0, 0, 0));
    overflow-y: scroll;

    /* border: 4px dashed yellow; */
  }

  /* Content With the Text for each Media/Anime */
  .card-content-container {
    border: 2px solid #dad8d2;
    height: 80%;
    min-height: 80%;
    max-height: 80%;
    text-align: left;
    overflow-y: scroll;
    padding: 2% 4%;
    border-radius: 1%;
    /* Changed Position for fixed height */
    position: absolute;
    z-index: 2;
    /* Center Align */
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    /* Testing purposes we add a border */
    /* border: 4px dashed blue; */
  }

  .card-text {
    overflow-y: scroll;
  }

  .media-text {
    font-size: 2vh;
    transition: all 0.5s ease;
    margin-top: 0;
    padding-bottom: 0;
    color: white;
    opacity: 1;
    text-shadow: 4px 4px 4px black;
  }

  /* Card Container */

  /* Media Tabs */

  .card-container-tab {
    flex-grow: 1;
    margin-top: auto !important;
    /* background: linear-gradient(rgba(0, 0, 0, 0), black); */
    overflow-y: scroll;

    /* border: 4px dashed yellow; */
  }

  .grid-column-mobile {
    scroll-behavior: smooth;
    overflow-y: scroll;
    white-space: nowrap;
  }

  .media-logo {
    max-height: 15vh;
    max-width: 100%;
  }

  .nav-container {
    display: inline-block;
  }

  .nav-image-container {
    display: inline-block;
    text-align: center;
    width: 100% !important;
    margin-bottom: 0;
    margin: 0;
    height: 100% !important;
    border-radius: 0;
    background-color: black;
    background: linear-gradient(rgba(0, 0, 0, 0), black);
    border: 2px solid rgb(218, 216, 210);
  }

  .nav-image-container img {
    display: inline-block;
    text-align: center;
    height: 100% !important;
  }

  .nav-image-container:hover {
    /* background: grey; */
    background: linear-gradient(rgba(0, 0, 0, 0), rgb(98, 98, 98));
    transition: all 1s ease;
    transform: translateX(0);
  }

  /* Media Tabs */

  /* Card Tabs */

  .card-button {
    background-color: rgba(0, 0, 0, 0) !important;
    border: none;
    color: white;
    font-weight: 600;
    margin: 0.5em auto !important;
  }

  .active {
    pointer-events: none;
    /* background: grey; */
    background: linear-gradient(rgba(0, 0, 0, 0), rgb(98, 98, 98));
    background-position: left;
    filter: initial;
  }

  .nav-image-container img {
    margin: auto;
    display: block;
    filter: brightness(0) invert(1);
  }

  /* Card Tabs */

  .overlay-quote {
    bottom: 3vh;
  }

  .menu-button {
    position: fixed;
  }

  /* De-Decor */

  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  /* De-Decor */

  .react-wallpaper {
    opacity: 1;
  }

  /* Wallpaper Mobile */

  #hnk {
    filter: brightness(50%);
    transition: all 1s ease;
    background: url(https://kawaii-mobile.com/wp-content/uploads/2018/02/Houseki-no-Kuni-Phosphophyllite-1080x1920-168x300.jpg)
      no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  #pmmm {
    filter: brightness(50%);
    transition: all 1s ease;
    background: url(https://mfiles.alphacoders.com/298/298775.jpg) no-repeat
      center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  #csm {
    filter: brightness(50%);
    transition: all 1s ease;
    background: url(https://w0.peakpx.com/wallpaper/426/973/HD-wallpaper-monochrome-anime-girls-chainsaw-man-makima-chainsaw-man.jpg)
      no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  #wha {
    filter: brightness(50%);
    transition: all 1s ease;
    background: url(https://i2.wp.com/theturnaroundblog.com/wp-content/uploads/2019/04/9781632367709_IL_1_53404.jpg?fit=547%2C779&ssl=1)
      no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  #tpnl {
    filter: brightness(50%);
    transition: all 1s ease;
    background: url("./wallpapers/mobiletpnl.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }
}
